.globalLoader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.local {
  height: 150px;
  width: 150px;
}

.centered {
  width: 100%;
}

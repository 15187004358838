@import "@Styles/vars";
@import "@Styles/animation";

.icon {
  display: inline-block;

  &.spin {
    animation: spin 1.5s linear infinite;
  }
}
